.videoEditor {
  display: grid;
  justify-content: space-between;
  grid-template-columns: 45% 50%;
}

.videoEditor > div > h2 {
  font-family: "Arvo", serif;
  font-size: 1.5rem;
  border-bottom: 1px solid #000;
  padding-bottom: 7px;
}

.videoEditor > div {
  padding-bottom: 100px;
}

.entitySelector {
  padding: 15px;
}

.entitySelector h4 {
  font-family: "Arvo", serif;
  margin: 0;
  margin-bottom: 25px;
}
