.buttons {
  max-width: 1024px;
  margin: auto;
  margin-top: 10px;
}

.empty {
  text-align: center;
  padding-top: 55px;
  padding-bottom: 55px;
}

.empty h1 {
  font-size: 6.125rem;
  margin: 0;
  font-family: "Arvo", serif;
}

.empty h2 {
  font-size: 3rem;
  margin: 0;
  font-family: "Arvo", serif;
}
