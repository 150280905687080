.filters {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 35px;
}

.videoContainer {
  display: grid;
  grid-template-columns: 28% 70%;
  justify-content: space-between;
}

.videoListing {
  padding-bottom: 100px;
}

.videos > span {
  margin-bottom: 35px;
}

.videos {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  justify-content: space-between;
}

.noVideos {
  min-height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  font-weight: bold;
  font-size: 1.25rem;
  grid-column: 1 / -1;
}

.videosFilesTab {
  position: absolute;
  bottom: 0;
  right: 0;
}

.videosFilesTab ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

.videosFilesTab ul li {
  display: block;
  height: 50px;
  cursor: pointer;
  line-height: 50px;
  padding: 0 20px;
  font-family: "Arvo", serif;
  font-size: 0.9rem;
}

.videosFilesTab :global ul li.active {
  background-color: #a69882;
}

.fileListingContainer ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: 32% 32% 32%;
  justify-content: space-between;
}

.file {
  padding: 15px;
  border: 1px solid #e2d8c8;
  margin-bottom: 25px;
  cursor: pointer;
}

.file :global .name {
  font-size: 1.25rem;
  color: #666057;
  font-weight: 500;
}

.file :global .type {
  color: #666057;
  font-size: 0.875rem;
}

.file :global .date {
  display: block;
  margin-top: 10px;
  color: #666057;
  font-size: 0.875rem;
}
