.video {
  width: 100%;
  height: auto;
  margin-bottom: 35px;
  cursor: pointer;
  position: relative;
}

.video h1 {
  font-size: 1rem;
  color: #666057;
  font-weight: 500;
  margin: 0;
}

.thumbnail {
  margin-bottom: 10px;
  background-color: #848484;
  padding-top: 62.5%;
  background-size: cover;
  background-position: center;
  transition: all 0.2s ease-in-out;
}

.thumbnail img {
  width: 100%;
  height: auto;
  opacity: 1;
}
