.files {
  max-width: 1024px;
  height: auto;
  margin: auto;
  margin-top: 35px;
}

.files h1 {
  font-family: "Arvo", serif;
  font-size: 1.5rem;
  border-bottom: 1px solid #000;
  padding-bottom: 7px;
}

.files li {
  cursor: pointer;
  text-decoration: underline;
}

.empty {
  text-align: center;
  list-style: none;
}

.empty {
  text-decoration: none !important;
  cursor: default !important;
}
