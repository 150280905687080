.button {
  height: 40px;
  background: #0b0b0b;
  color: #fff;
  border-radius: 5px;
  display: block;
  appearance: none;
  cursor: pointer;
  outline: none;
  border: 0;
  font-family: "Arvo", serif;
  font-size: 1rem;
  text-transform: uppercase;
  width: 100%;
}

.inner {
  position: relative;
  max-width: 1150px;
  height: 100%;
  margin: auto;
}

.page main > header.page_header {
  height: 135px;
  background: #e2d8c8;
  margin-bottom: 35px;
}

.page main > header.page_header h1 {
  position: absolute;

  padding: 0;
  margin: 0;

  bottom: 30px;
  left: 0;

  font-family: "Arvo", serif;
  font-size: 2rem;
}

.page .page_content .dataTableContainer {
  width: 100%;
  margin-top: 25px;
  padding-bottom: 55px;
}

.page .page_content .toolbarContainer button {
  margin-right: 15px;
}

.form .formRow {
  margin-bottom: 21px;
}

.form .formRow .error {
  color: red;
  font-weight: bold;
  padding-top: 5px;
  display: inline-block;
}

.form .formRow .info {
  color: gray;
  font-weight: bold;
  padding-top: 5px;
  display: inline-block;
}

.form .formRow label {
  display: block;
  font-size: 1.125em;
  font-weight: bold;
  margin-bottom: 7px;
  font-family: "Arvo", serif;
}

.form .formRow input {
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 2px solid #0b0b0b;
  border-radius: 5px;
  font-size: 1.4em;
  padding: 4px;
  box-sizing: border-box;
}

.form .formRow.buttons button {
  margin-bottom: 10px;
}

.form .formRow button {
  display: block;
  appearance: none;
  outline: none;
  border: 0;
  background-color: var(--toolbar-1);
  text-align: center;
  font-size: 1.125em;
  text-transform: uppercase;
  color: #ffffff;
  width: 100%;
  height: 40px;
  border-radius: 5px;
}
