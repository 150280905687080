.player {
  max-width: 1024px;
  height: auto;
  margin: auto;
}

.container {
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;
}

.container iframe {
  border: 2px solid #0b0b0b;
  box-sizing: border-box;
  border-radius: 5px;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
