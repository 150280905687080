.messagesPane {
  display: flex;
  justify-content: space-between;
  height: 700px;
}

.list {
  width: 300px;
  border-right: 1px solid #d5d5d5;
}

.messages {
  width: 800px;
}

.selectedMessage header {
  border-bottom: 1px solid #d5d5d5;
  padding-bottom: 20px;
  margin-bottom: 35px;
}

.selectedMessage header h1 {
  font-size: 1.45rem;
  font-family: "Arvo", serif;
  margin: 0;
  margin-bottom: 5px;
}

.date {
  font-size: 0.875rem;
  color: #575757;
}

.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #575757;
  height: 100%;
}
