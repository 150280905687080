.loginScreen {
  display: grid;
  grid-template-columns: 38% 62%;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
}

.loginPane {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding-top: 41px;
  padding-left: 52px;
  padding-right: 52px;
}

.loginPane header {
  position: absolute;
  top: 41px;
  left: 52px;
}

.logo {
  width: 244px;
  height: auto;
}

.form {
  width: 100%;
}

.form h2 {
  font-family: "Arvo", serif;
  font-size: 1.75rem;
  font-weight: normal;
  margin: 0;
  color: #000;
  margin-bottom: 42px;
}

.form button {
  margin-bottom: 21px;
}

.brandPane {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #e2d8c8;
}

.brandOverlayImage {
  position: absolute;
  bottom: 45px;
  right: 45px;
}

.brandLogoPane {
  position: absolute;
  top: 35px;
  left: 35px;
  background-color: #666057;
  height: 140px;
  width: 90%;
  padding: 27px;
  padding-left: 37px;
  box-sizing: border-box;
}

.brandLogoPane img {
  height: 100%;
  width: auto;
}
