.container {
  width: 345px;
  margin: auto;
}

.container h1 {
  text-align: center;
  font-size: 2.2rem;
  text-transform: uppercase;
  font-family: "Arvo", serif;
  margin-bottom: 50px;
}

.registrationScreen {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
