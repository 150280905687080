.topBar {
  position: relative;
  height: 67px;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  z-index: 50;
}

.topBar h1 {
  margin: 0;
  padding: 0;
  height: auto;
  cursor: pointer;
}

.topBar .inner {
  height: 100%;
}

.grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.search {
  width: 560px;
  height: 36px;
}

.search :global .ui.icon.input {
  width: 100%;
}
