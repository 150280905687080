.userEditor {
  display: grid;
  justify-content: space-between;
  grid-template-columns: 35% 60%;
}

.userEditor > div > h2 {
  font-family: "Arvo", serif;
  font-size: 1.5rem;
  border-bottom: 1px solid #000;
  padding-bottom: 7px;
}

.userEditor > div {
  padding-bottom: 100px;
}
