.groupEditor {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.fullcol {
  width: 100%;
}

.groupEditor section {
  padding-bottom: 100px;
}

.groupEditor section > h2 {
  font-family: "Arvo", serif;
  font-size: 1.5rem;
  border-bottom: 1px solid #000;
  padding-bottom: 7px;
}
